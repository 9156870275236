<template>
  <div>
    <form-wizard
      color="var(--accent3-color)"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text='$t(`message.finish_btn`)'
      :back-button-text='$t(`message.btn_previous`)'
      :next-button-text='$t(`message.btn_next`)'
      class="mb-3"
      @on-complete="formSubmitted"
    >

    <template>
      <div>
        <b-alert
          :show="dismissCountDown2"
          dismissible
          fade
          variant="danger"
          @dismiss-count-down="countDownChanged2"
        >
          {{$t('message.maxtravellers')}}
        </b-alert>
      </div>
    </template>

      <!-- quote details tab -->
      <tab-content
        :title='$t(`message.quote_details`)'
        :before-change="validationForm"
        style="position:relative"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >

        <div id="savedQuotationsDrop" v-if="previousQuotations.length>0" style="text-align:right;">
          <b-dropdown id="dropdown-1" dropleft text="Previous Quotations" size="sm" class="dropdown-menu-end">
            <b-dropdown-item @click="newQuotation()">New Quotation</b-dropdown-item>
            <b-dropdown-item v-for="(p,index) in previousQuotations" :key="p.index" @click="sessionQuotation(p[0])">{{p[1]}}</b-dropdown-item>
          </b-dropdown>
        </div>

          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="h5_style">
                {{$t('message.rq')}} 
              </h5>
              <small class="small_style">
                {{$t('message.entrdts')}}
              </small>
              
            </b-col>

            <!-- quotation -->
            <!-- <b-col md="6">
              <b-form-group
                :label='$t(`message.onbehalfof`)'
                label-for="v-behalf-of"
              >
                <v-select
                  id="v-behalf-of"
                  v-model="selectedBehalfOf"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="behalfOf"
                  :selectable="option => ! option.value.includes('Choose...')"
                  :clearable="false"
                  label="text"
                  @input="synchBehalfOf"
                />
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <input type="hidden" name="Sell on behalf of" id="selectedBehalfOfHidden" v-model="selectedBehalfOfHidden" :state="errors.length > 0 ? false:null">
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="6">
              <b-form-group
                :label='$t(`message.agent`)'
                label-for="v-agent"
              >
                <v-select
                  id="v-agent"
                  v-model="selectedAgent"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="agentName"
                  :selectable="option => ! option.value.includes('To...')"
                  :clearable="false"
                  label="text"
                  @input="agentSelected"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group
                :label='$t(`message.f_country`)'
                label-for="v-country-from"
              >
                <v-select
                  id="v-country-from"
                  v-model="selectedContryFrom"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countrysFrom"
                  :selectable="option => ! option.value.includes('From...')"
                  :clearable="false"
                  label="text"
                  @input="select_country_from"
                />
                <!-- start select validation -->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <input type="hidden" name="From Country" id="fromCountryfHidden" v-model="fromCountryfHidden" :state="errors.length > 0 ? false:null">
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- end select validation -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.t_country`)'
                label-for="v-country-destinations"
              >
                <v-select
                  id="v-country-destinations"
                  v-model="selectedContryTo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countrysTo"
                  :selectable="option => ! option.value.includes('To...')"
                  :clearable="false"
                  label="text"
                  @input="select_country_to"
                />
                <!-- start select validation -->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <input type="hidden" name="To Destinations" id="toCountryfHidden" v-model="toCountryfHidden" :state="errors.length > 0 ? false:null">
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- end select validation -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.via`)'
                label-for="v-via"
              >
                <v-select
                  taggable 
                  multiple
                  id="v-via"
                  v-model="selectedVia"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryVia"
                  label="text"
                  @input="select_country_via"
                  :disabled="viastate"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.input_ages`)'
                label-for="input_ages"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Insured Persons"
                  rules="required"
                >
                  <b-form-input
                    id="input_ages"
                    v-model="input_ages"
                    :state="errors.length > 0 ? false:null"
                    placeholder="23, 53, 58"
                    @input="updateAges"
                    @keypress="isNumber" 
                    @blur="addTravellers"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}.</small>
                  <small class="text-danger">{{ duplicatecommas }}</small>
                  <small class="text-danger">{{ adult_supervisor }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.tr_number`)'
                label-for="travellers_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="travellers number"
              
                >
                  <b-form-input
                    id="travellers_number"
                    type="number"
                    readonly
                    v-model="travellers_number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.booking_cost`)'
                label-for="booking_costs"
              >
                <validation-provider
                  #default="{ errors }"
                  name="booking costs"
                >
                  <b-form-input
                    id="booking_costs"
                    type="number"
                    v-model="booking_costs"
                    placeholder="0"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='date_range'
                label-for="daterange"
              >
              <v-date-picker :key="datePickerKey" v-model="range" is-range :min-date='new Date()' @input="daterangePicker" :locale='$t(`message.locale`)' :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="d-flex justify-center items-center">
                    <input
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      class="form-control"
                    />
                    <svg
                      class="w-4 h-4 mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width="20px"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                    <input
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      class="form-control"
                    />
                  </div>
                </template>
              </v-date-picker>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                :label='$t(`message.policyholder`)'
                label-for="policyholder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="policyholder"
                >
                  <b-form-input
                    id="policyholder"
                    type="number"
                    v-model="policyholder"
                    placeholder="type policyholder"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="6">
              <b-form-group
                :label='$t(`message.select_clauses`)'
                label-for="v-select-clauses"
              >
                <v-select
                  taggable 
                  multiple
                  id="v-select-clauses"
                  v-model="selectedClauses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clausesArr"
                  :selectable="option => ! option.value.includes('Select...')"
                  label="text"
                  @input="showClauses"
                />
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="12">
              <b-form-group
                :label='$t(`message.clauses`)'
                label-for="v-show-clauses"
              >
                <textarea rows="7" style="width:100%;" name="showclaussess" id="showclaussess"></textarea>
              </b-form-group>
            </b-col> -->
          </b-row>

          <!-- quotation -->

    
        </validation-observer>
      </tab-content>

      <!-- products info tab -->
      <tab-content
        :title='$t(`message.products_info`)'
        :before-change="validationFormInfo"
      >
        <template>
          <div>
            <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              {{alertMsg}}
            </b-alert>
          </div>
        </template>

        <b-row>
          <b-col
            cols="12"
            class="text-center"
            >
            <button class="btn btn-success" id="getQuote" @click="getQuote($event)">{{get_quote_text}}</button>
          </b-col>
          <b-col
            cols="12"
          >
          <div class="d-flex justify-content-end" v-if="showProducts">
            <font-awesome-icon @click="classicViewLayout" class="cursor-pointer mr8" icon="fa-solid fa-list" size="lg" v-b-tooltip.hover.top='"classic view"'/>
            <font-awesome-icon @click="ListViewLayout" class="cursor-pointer" icon="fa-solid fa-table" size="lg" v-b-tooltip.hover.top='"list view"'/>
          </div>
          </b-col>
          <!-- start choose trip type -->
          <b-col
          v-if="this.showProducts"
          cols="12"
          class="text-center mt-2"
          >
            <div>
              <b-button-group>
                <b-button class="singletripbtn active" @click='showSelectedTypeProducts($event,"Longstay","Annual")'>Single Trip</b-button>
                <b-button class="longstaytripbtn" @click='showSelectedTypeProducts($event,"SingleTrip","Annual")'>Longstay</b-button>
                <b-button class="annualtripbtn" @click='showSelectedTypeProducts($event,"SingleTrip","Longstay")'>Annual Multi-Trip <font-awesome-icon icon="fa-solid fa-circle-info" style="margin-left:3px;" v-b-tooltip.hover.top='$t(`message.policy_refers`)' /></b-button>
              </b-button-group>
            </div>
          </b-col>
          <!-- end choose trip type -->
          <b-col
            cols="12"
            class="text-center mt-2"
            >
            <Transition name="slide-fade">
              <div class="row" id="products_panel" v-if="showProductsClassic && showProducts" @click="disableSliderScroll">
                <ClassicProductView @selectProductChange="selectProduct" v-for="(product, index) in ajax_products" :key="product.index" :product_price="product_price[index]" :product_currency="product_currency[index]" :product_currency_symbol="product_currency_symbol" :product_category="product_category[index]" :product_code="product_code[index]" :product_description="product_description[index]" :product_name="product_name[index]" :product_short_description="product_short_description[index]" :primary="primary[index]" :secondary="secondary[index]" :resCheckName="resCheckName" :destCheckName="destCheckName" :startDate="initialRequest['start_date']" :daysNum="initialRequest['days']" :agesArr="agesArr"/>
              </div>
            </Transition>
            <Transition name="slide-fade">
              <div class="row" id="products_panel_list" v-if="showProductsList && showProducts" @click="enableSliderScroll">
                <ListProductView @selectProductChange="selectProduct" v-for="(product, index) in ajax_products" :key="product.index" :product_price="product_price[index]" :product_currency="product_currency[index]" :product_currency_symbol="product_currency_symbol" :product_category="product_category[index]" :product_code="product_code[index]" :product_description="product_description[index]" :product_name="product_name[index]" :product_short_description="product_short_description[index]" :primary="primary[index]" :secondary="secondary[index]" :resCheckName="resCheckName" :destCheckName="destCheckName" :startDate="initialRequest['start_date']" :daysNum="initialRequest['days']" :agesArr="agesArr"/>
              </div>
            </Transition>
          </b-col>
          <b-col cols="12" class="text-center" v-if="showProducts && ajax_products.length > 0">
            <b-button variant="secondary" v-b-modal.modal-offer>Save Offer</b-button>
            <!-- save offer modal -->
            <b-modal id="modal-offer" title="Save Offer" centered @ok="handleOk">
              <b-form ref="offerForm" @submit.stop.prevent="handleOfferSubmit">
                <b-form-input v-model="offerName" placeholder="Plese enter a name for this offer" required minlength="6"></b-form-input>
                <span class="text-danger">{{ errorSOffer }}</span>
              </b-form>
            </b-modal>
            <!-- message offer modal -->
            <b-modal v-model="offermessagemodalshow" id="modal-offermessage" title="Saved Offer" centered>
              <p class="text-success">Offer has been saved</p>
            </b-modal>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <template>
              <div>
                <b-alert
                  :show="dismissCountDown1"
                  dismissible
                  fade
                  variant="danger"
                  @dismiss-count-down="countDownChanged1"
                >
                  {{$t('message.no_products')}}
                </b-alert>
              </div>
            </template>
          </b-col>
        </b-row>
      </tab-content>

      <!-- travellers info tab -->
      <tab-content
        :title='$t(`message.travellers`)'
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
          id="travellers_form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="h5_style">
                {{$t(`message.travellers`)}}
              </h5>
              <small class="small_style">{{$t(`message.entr_tr_dts`)}}</small>
            </b-col>
          </b-row>
          <b-row v-for="(tr_item,i) in numberoftravellers" :key="tr_item.id" class="mb-3">
            <b-col cols="12">
              <h4 class="mb-1 travellerstitle" v-html="compinedHTML($t(`message.traveller`), i)"></h4>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.title`)'
                label-for="title"
              >
              <v-select
                :id="'traveller_title'+(i+1)"
                :name="'traveller_title'+(i+1)"
                v-model="tr_item.title"
                :options="traveller_select"
                label="text"
                :clearable="false"
                @input="select_title_to($event, i)"
              />
              <input type="hidden" :id="'traveller_title_hidden'+(i+1)" value="Mr.">
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.first_name`)'
                label-for="First Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required|min:3"
                  :vid="tr_item.firstname"
                >
                  <b-form-input
                    class="travellersValidate"
                    :id="'firstName'+(i+1)"
                    :name="'firstName'+(i+1)"
                    v-model="tr_item.firstname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="First Name"
                    autocomplete="off"
                    @change="saveFN($event, i)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.last_name`)'
                label-for="Last Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    class="travellersValidate"
                    :id="'lastName'+(i+1)"
                    :name="'lastName'+(i+1)"
                    v-model="tr_item.lastname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Last Name"
                    autocomplete="off"
                    @change="saveLN($event, i)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="
                dateBirth_i=='en' ? dateBirthLabel[i].en 
                : dateBirth_i=='fr' ? dateBirthLabel[i].fr 
                : dateBirth_i=='de'? dateBirthLabel[i].de 
                : dateBirth_i=='es'? dateBirthLabel[i].es 
                : dateBirth_i=='pl'? dateBirthLabel[i].pl 
                : dateBirth_i=='ro'? dateBirthLabel[i].ro 
                : dateBirth_i=='it'? dateBirthLabel[i].it 
                : dateBirthLabel[i].gr"
                label-for="dateofbirth"
                >
                <validation-provider
                  #default="{ errors }"
                  name="Date of birth"
                  rules="required"
                >
                  <v-date-picker v-model="tr_item.date" mode="date" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                      :id="'dateofbirth'+(i+1)"
                      :name="'dateofbirth'+(i+1)"
                      class="form-control form_control_dateofbirth"
                      :value="inputValue"
                      v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.mobile`)'
                label-for="mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                >
                  <b-form-input
                    type="number"
                    :id="'mobile'+(i+1)"
                    :name="'mobile'+(i+1)"
                    v-model="tr_item.mobile"
                    :state="errors.length > 0 ? false:null"
                    placeholder="8586883484"
                    autocomplete="off"
                    @change="saveMB($event, i)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              cols="12"
              class="mb-0"
            >
              <h5 class="h5_style mb-1">
                {{$t(`message.pdts`)}}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.ce`)'
                label-for="contact_email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contact Email"
                  rules="required|email"
                >
                  <b-form-input
                    type="email"
                    id="contact_email"
                    v-model="contact_email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="valid@email.com - An e-mail containing the insurance Policy will be delivered on this address."
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.cust_street`)'
                label-for="customer_street"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer's Street"
                  rules="required|min:4"
                >
                  <b-form-input
                    id="customer_street"
                    v-model="customer_street"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Holden Street"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.street_number`)'
                label-for="street_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Street Number"
                  rules="required"
                >
                  <b-form-input
                    id="street_number"
                    v-model="street_number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="50"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.area`)'
                label-for="customer_area"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Area"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="customer_area"
                    v-model="customer_area"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Chinatown"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.city`)'
                label-for="customer_city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="customer_city"
                    v-model="customer_city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="London"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.ctin`)'
                label-for="tax_identification_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer's Tax Identification Number"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="tax_identification_number"
                    v-model="tax_identification_number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="9999999999"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.c_tel`)'
                label-for="customer_telephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer's Telephone"
                  rules="required|min:10"
                >
                  <b-form-input
                    type="number"
                    id="customer_telephone"
                    v-model="customer_telephone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="8586883484"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.czip`)'
                label-for="customer_zip_code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer's Zip Code"
                  rules="required|min:4"
                >
                  <b-form-input
                    id="customer_zip_code"
                    v-model="customer_zip_code"
                    :state="errors.length > 0 ? false:null"
                    placeholder="92109"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label='$t(`message.c_country`)'
                label-for="v-country-policyholder"
              >
                <v-select
                  id="v-country-policyholder"
                  v-model="selectedContryPolicyHolder"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countrysFrom"
                  :selectable="option => ! option.value.includes('From...')"
                  label="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="this.check_product=='product-single-919'">
              <b-form-group
                :label='$t(`message.school`)'
                label-for="customer_school"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer's School"
                  :rules="`${this.check_product=='product-single-919' ? 'required' : ''}`"
                >
                  <b-form-input
                    id="customer_school"
                    v-model="customer_school"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Alfreton School, Greem Street, London, 71601"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-1">
            <div class="col-12">
              <b-button class="d-block mx-auto" v-b-toggle.collapse-1 variant="secondary">{{$t(`message.additional_info`)}}</b-button>
              <b-collapse :visible="additional_info_visible" id="collapse-1" class="mt-2">
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.contr`)'
                        label-for="contracted_party_type"
                      >
                        <v-select
                          id="contracted_party_type"
                          v-model="contractedPartyTypeSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="contractedPartyType"
                          :selectable="option => ! option.value.includes('From...')"
                          :clearable="false"
                          label="text"
                          @input="setContractedPartyType"
                        />
                      </b-form-group>
                    </b-col>
                    <!--  -->
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.company_name`)'
                        label-for="company_name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Company Name"
                          :rules="`${this.setcompanyName ? 'required' : ''}`"
                        >
                          <b-form-input
                            id="company_name"
                            v-model="company_name"
                            :state="errors.length > 0 ? false:null"
                            :disabled="companyNameDisabled"
                            placeholder="company name"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.doc_type`)'
                        label-for="document_type"
                      >
                        <v-select
                          id="document_type"
                          v-model="documentTypeSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="documentType"
                          :selectable="option => ! option.value.includes('From...')"
                          :clearable="false"
                          label="text"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.doc_number`)'
                        label-for="document_number"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Document Number"
                          :rules="`${this.product_is_mondial ? '' : ''}`"
                        >
                          <b-form-input
                            id="document_number"
                            v-model="document_number"
                            :state="errors.length > 0 ? false:null"
                            placeholder="AA09984"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.doc_auth`)'
                        label-for="document_authority"
                      >
                        <v-select
                          id="document_authority"
                          v-model="documentAuthoritySelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="documentAuthority"
                          :selectable="option => ! option.value.includes('From...')"
                          :clearable="false"
                          label="text"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.doc_auth_cerf`)'
                        label-for="document_authority_certification"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Document Authority Certification"
                          :rules="`${this.product_is_mondial ? '' : ''}`"
                        >
                          <b-form-input
                            id="document_authority_certification"
                            v-model="document_authority_certification"
                            :state="errors.length > 0 ? false:null"
                            placeholder="tax authority or police department or authority issuing the passport"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.trip_text`)'
                        label-for="trip_info_text"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Trip Information Text"
                          :rules="`${this.product_is_mondial ? '' : ''}`"
                        >
                          <b-form-input
                            id="trip_info_text"
                            v-model="trip_info_text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="one way ticket"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.arr_city`)'
                        label-for="arrival_city"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Arrival City"
                          :rules="`${this.product_is_mondial ? '' : ''}`"
                        >
                          <b-form-input
                            id="arrival_city"
                            v-model="arrival_city"
                            :state="errors.length > 0 ? false:null"
                            placeholder="London"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label='$t(`message.travel_price`)'
                        label-for="travel_price"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Travel Price"
                          :rules="`${this.product_is_mondial ? '' : ''}`"
                        >
                          <b-form-input
                            type="number"
                            id="travel_price"
                            v-model="travel_price"
                            :state="errors.length > 0 ? false:null"
                            placeholder="50"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
              </b-collapse>
            </div>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- preview info tab -->
      <tab-content
        :title='$t(`message.preview`)'
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
        <b-row>
          <b-col
            cols="12"
            class="mb-0"
          >
          <h1 class="mb-2 previewdts">
            {{$t(`message.preview_dts`)}}
          </h1>
          </b-col>
        </b-row>
          <b-row id="reviewPanel">
              <form class="col-12">
                <fieldset>
                  <legend>{{$t(`message.about_y_trip`)}}</legend>
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.type_of_policy`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" class="form-control" :value="this.initialRequest['product_category'] || ' -'" disabled>
                    </div>
                  </div>          
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.tr_ins_policy`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewPolicy" class="form-control" :value="initialRequest['product_name'] || ' -'" disabled>
                    </div>
                  </div>          
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.what_c_r`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewResidence" class="form-control" :value="initialRequest['res_name'] || ' -'" disabled>
                    </div>
                  </div>          
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.where_c_r`)}} </label>
                    <div class="col-md-3 pad_preview previewquestions">
                      <input type="text" id="previewDestination" class="form-control" :value="initialRequest['des_name'] || ' -'" disabled>
                    </div>
                    <div class="col-md-1">
                      <font-awesome-icon id="popover-target-desktop" class="cursor-pointer" icon="fa-solid fa-question"/>
                      <b-popover target="popover-target-desktop" triggers="hover" placement="top">
                        <template #title>{{$t(`message.classed_europe`)}}</template>
                          {{$t(`message.europe_countries`)}}
                      </b-popover>
                    </div>
                  </div>
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.inter_countries`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewVia" class="form-control" :value="initialRequest['via_countries'] || ' -'" disabled>
                    </div>
                  </div> 
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.tr_number_cap`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewTravellerNumber" class="form-control" :value="initialRequest['travellersNumber'] || ' -'" disabled>
                    </div>
                  </div>           
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.start_date_policy`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewStartDate" class="form-control" :value="initialRequest['start_date'] || ' -'" disabled>
                    </div>
                  </div>          
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.end_date_policy`)}}</label>
                    <div class="col-md-3 pad_preview" v-if="this.initialRequest.product_category !== 'Annual'">
                      <input type="text" id="previewEndDate" class="form-control" :value="initialRequest['end_date'] || ' -'" disabled>
                    </div>
                    <div class="col-md-3 pad_preview" v-else>
                      <input type="text" id="previewEndDate" class="form-control" :value="initialRequest['annual_date'] || ' -'" disabled>
                    </div>
                  </div>          
                  <div class="form-group row align-items-center">
                    <label class="col-md-7 col-form-label">{{$t(`message.number_of_days`)}}</label>
                    <div class="col-md-3 pad_preview">
                      <input type="text" id="previewTripDates" class="form-control" :value="initialRequest['days'] || ' -'" disabled>
                    </div>
                  </div> 
                </fieldset>
              </form>       
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- buy policy tab  -->
      <tab-content
        :title='$t(`message.buy_policy`)'
      >
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div>
            <b-jumbotron class="sm_jumbotron" :header="$t(`message.introduction`)" header-level="5">
              <p class="lead">{{$t(`message.ins_dts_1`)}}</p>
              <p class="lead">{{$t(`message.ins_dts_2`)}}</p>
            </b-jumbotron>
          </div>
        </b-col>
        <b-col
          cols="12"
        >
          <div>
            <b-jumbotron class="sm_jumbotron" :header="$t(`message.agreements`)" header-level="5">
              <h4>{{$t(`message.imp_cond`)}}</h4>
              <p class="lead">{{$t(`message.introduction`)}}<br>{{$t(`message.ins_dts_3`)}}<br>{{$t(`message.ins_dts_4`)}}<br>{{$t(`message.ins_dts_5`)}}</p>
              <p class="lead">{{$t(`message.ins_dts_6`)}}</p>
              <template>
                <div>
                  <b-form-radio-group
                    v-model="selected_coverages"
                    :options="coverages_options"
                    class="mb-2"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                    @change="coveragesOptions"
                  ></b-form-radio-group>
                </div>
              </template>
              <h4>{{$t(`message.personal_data`)}}</h4>
              <p class="lead">{{$t(`message.ins_dts_7`)}}</p>
              <h4>{{$t(`message.health_cond`)}}</h4>
              <p class="lead">{{$t(`message.ins_dts_8`)}}</p>
              <h4>{{$t(`message.pre_exs`)}}</h4>
              <p class="lead">{{$t(`message.ins_dts_9`)}}</p>
              <b-link tag="button" class="btn btn-outline-secondary" href="#" target="_blank">{{$t(`message.declaration`)}}</b-link>
              <h4 class="mt-2">{{$t(`message.pol_rel`)}}</h4>
              <ol type="a" class="lead">
                <li>{{$t(`message.pol_li_1`)}}</li>
                <li>{{$t(`message.pol_li_2`)}}</li>
                <li>{{$t(`message.pol_li_3`)}}</li>
                <li>{{$t(`message.pol_li_4`)}}</li>
                <li>{{$t(`message.pol_li_5`)}}</li>
                <li>{{$t(`message.pol_li_6`)}}</li>
              </ol>
              <p class="lead">{{$t(`message.awr_med`)}}</p>
              <template>
                <div>
                  <b-form-radio-group
                    v-model="selected_treatment"
                    :options="treatment_options"
                    class="mb-2"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                    @change="treatmentOptions"
                  ></b-form-radio-group>
                </div>
              </template>
              <p class="lead">{{$t(`message.agree_med`)}}</p>
              <template>
                <div>
                  <b-form-radio-group
                    v-model="selected_medical"
                    :options="medical_options"
                    class="mb-2"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                    @change="medicalOptions"
                  ></b-form-radio-group>
                </div>
              </template>
              <p class="lead font-700">{{$t(`message.understand_med`)}}</p>
            </b-jumbotron>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <template>
            <div>
              <b-form-checkbox :disabled="checkpolicystatus" v-model="switched_checked_accept" name="check-button" switch @change="check_allow_buy">
                <b>{{$t(`message.accept_cond`)}}</b>
              </b-form-checkbox>
            </div>
          </template>
        </b-col>
        <b-col
        cols="12"
        class="text-center mt-2"
        >
          <b-button variant="success" size="lg" :disabled="buypolicystatus" @click='buyProduct($event)'>{{buy_policy_text}}</b-button>
          <b-button class="ml-1" v-if='paymentdts' variant="secondary" v-b-modal.paymentdetailsmodal>{{$t(`message.payment_dts`)}}</b-button>

          <!--start payment modals -->
          <b-modal id="paymentdetailsmodal" title="Policy Status" v-model="paymentmodalshow" ok-only>
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              <font-awesome-icon icon="fa-solid fa-check"/> <strong>{{$t(`message.thx_messgae`)}}</strong>
              <div v-if="display_payment_ammount">
                <a :href="hrefpaymentlink" target="_blank" class="btn btn-sm btn-outline-success d-block font-weight-bolder">{{$t(`message.payment_proceed`)}}</a>
                <div class="mt-1">
                  <span>{{$t(`message.pay_using`)}}</span>
                  <img :src="vivacardsimg" alt="payment methods" class="img-fluid">
                </div>
              </div>
            </div>
            <ul id="listgroupdets" class="list-group">
              <li class="list-group-item" id="displayproduct"><span class='font-bold'>{{$t(`message.product`)}}</span> {{ displayproduct }}</li>
              <li class="list-group-item" id="displayprice"><span class='font-bold'>{{$t(`message.policy_price`)}}</span> {{ displayprice }}</li>
              <li class="list-group-item" id="payableto"><span class='font-bold'>{{$t(`message.payable_to`)}}</span> {{ payableto }}</li>
              <li class="list-group-item" id="payableamount" v-if="display_payment_ammount"><span class='font-bold'>{{$t(`message.payable_amount`)}}</span> {{ payableamount }}</li>
              <!-- <li id="displaypaymentli" v-if="display_payment_ammount" class="list-group-item"><a class='font-bold paymentlinka' id="displaypayment" :href="hrefpaymentlink" target="_blank">{{$t(`message.click_here_pay`)}}</a><button @click='copylink' class="btn btn-sm btn-outline-secondary ml-3">Copy link</button></li> -->
            </ul>
          </b-modal>

          <b-modal id="errorbuyProductModal" title="Policy Status Error" v-model="paymentmodalerrorshow" ok-only>
            <p class="lead" style="font-weight: 400;">{{ errorbuyProductModalMsg }}</p>
          </b-modal>
          <!-- end payment modals -->
        </b-col>
      </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import moment from 'moment'
import { countrysFrom, countrysTo, countryVia } from '@/products';
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  VBTooltip,
  BAlert,
  BCollapse,
  BButton,
  BButtonGroup,
  VBToggle,
  BCard,
  BSidebar,
  BJumbotron,
  BFormRadioGroup,
  BFormCheckbox,
  BLink,
  BPopover,
  VBModal,
  BDropdown,
  BDropdownItem, 
  BDropdownDivider,
  BForm
} from 'bootstrap-vue'
import { required, email, min } from '@validations'
import { codeIcon } from './code'
import ClassicProductView from './ClassicProductView'
import ListProductView from './ListProductView'
const mask = "DD-MM-YYYY";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    VBTooltip,
    ClassicProductView,
    ListProductView,
    BAlert,
    BCollapse,
    BButton,
    BButtonGroup,
    VBToggle,
    BCard,
    BSidebar,
    BJumbotron,
    BFormRadioGroup,
    BFormCheckbox,
    BLink,
    BPopover,
    VBModal,
    BDropdown,
    BDropdownItem, 
    BDropdownDivider,
    BForm
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-dropdown': BDropdown
  },
  data() {
    return {
      vivacardsimg: require('@/assets/images/quotation/cards_viva.svg'),
      alertMsg: '',
      agentParam:this.$store.state.app.agent,
      errorSOffer:'',
      sv_offer:{},
      offerName:'',
      viastate:true,
      datePickerKey:0,
      duplicatecommas:null,
      adult_supervisor:null,
      loadFormDts:[{},{},{},{}],
      dateBirth_i:'en',
      hrefpaymentlink:'',
      display_payment_ammount: true,
      displayproduct:'',
      displayprice:'',
      payablefrom:'',
      payableto:'',
      payableamount:'',
      errorbuyProductModalMsg:'',
      paymentmodalshow:false,
      offermessagemodalshow:false,
      paymentmodalerrorshow:false,
      paymentdts:false,
      all_products_arr:[],
      index:null,
      dateBirthLabel:[],
      prevval:'',
      todayval:'',
      birthDatesArr:[],
      buypolicystatus:true,
      checkpolicystatus:false,
      switched_checked_accept:false,
      selected_coverages:'A',
      coverages_options:[
        { item: 'A', name: 'Yes', value: 'Yes' },
        { item: 'B', name: 'No', value: 'No' },
      ],
      selected_treatment:'A',
      treatment_options:[
        { item: 'A', name: 'Yes', value: 'Yes' },
        { item: 'B', name: 'No', value: 'No' },
      ],
      selected_medical:'A',
      medical_options:[
        { item: 'A', name: 'Yes', value: 'Yes' },
        { item: 'B', name: 'No', value: 'No' },
      ],
      company_name:'',
      setcompanyName:'',
      additional_info_visible:false,
      product_is_mondial:false,
      product_is_kemter:false,
      product_is_battleface:false,
      product_is_sompo:false,
      productIsSelected:false,
      dismissSecs:5,
      dismissSecs1:2,
      dismissSecs2:5,
      dismissCountDown:0,
      dismissCountDown1:0,
      dismissCountDown2:0,
      showDismissibleAlert:false,
      selectedProduct:false,
      showProducts:false,
      showProductsClassic:true,
      showProductsList:false,
      ajax_products:[],
      masks:{
        input: mask
      },
      range:{
        start: new Date(),
        end: new Date(),
      },
      // dynamic values based on number of travellers 
      numberoftravellers:[],
      traveller_title:{
        value: 'Mr.', 
        text: 'Mr'
      },
      contact_email:'',
      customer_street:'',
      street_number:'',
      customer_area:'',
      customer_city:'',
      tax_identification_number:'',
      customer_telephone:'',
      selectedBehalfOf:'',
      selectedBehalfOfHidden:'',
      fromCountryfHidden:'',
      toCountryfHidden:'',
      selectedAgent:'',
      selectedContryFrom:'',
      selectedContryPolicyHolder:'',
      selectedContryTo:'',
      selectedVia:'',
      selectedContry:'',
      booking_costs:'',
      policyholder:'',
      selectedClauses:'',
      selectedClauseOperation:'',
      selectedAdditionalAmount:'',
      clauses_text:'',
      input_ages:'',
      travellers_number:'',
      selectedLanguage:'',
      name:'',
      emailValue:'',
      PasswordValue:'',
      passwordCon:'',
      first_name:'',
      last_name:'',
      address:'',
      landMark:'',
      pincode:'',
      twitterUrl:'',
      facebookUrl:'',
      googleUrl:'',
      linkedinUrl:'',
      city:'',
      required,
      email,
      codeIcon,
      customer_zip_code:'',
      customer_school:'',
      check_product:'',
      document_number:'',
      document_authority_certification:'',
      trip_info_text:'',
      arrival_city:'',
      travel_price:'',
      traveller_select:[
        {value: 'Mr', text: 'Mr'},
        {value: 'Mrs', text: 'Mrs'},
      ],
      contractedPartyType:[
        {value: 'no', text: 'No (policy holder is an individual)'},
        {value: 'yes', text: 'Yes (policy holder is a company)'},
      ],
      contractedPartyTypeSelected:[
        {value: 'no', text: 'No (policy holder is an individual)'}
      ],
      documentType:[
        {value: 'afm', text: 'Tax ID Number'},
        {value: 'adt', text: 'National ID'},
        {value: 'passport', text: 'Passport'},
      ],
      documentAuthority:[
        {value: 'afm', text: 'Tax ID Number'},
        {value: 'adt', text: 'National ID'},
        {value: 'passport', text: 'Passport'},
      ],
      documentTypeSelected:[
        {value: 'afm', text: 'Tax ID Number'}
      ],
      documentAuthoritySelected:[
        {value: 'afm', text: 'Tax ID Number'}
      ],
      countrysFrom, 
      countrysTo, 
      countryVia,
      agentName:[
        {value:'314', text:'pallidis@icloud.com'}
      ],
      behalfOf:[
        {value:'248', text:'Vasilis Pallidis Insurance Brokers'}
      ],
      clausesArr:[
        {value:'Covid-19 - The exclusion of Covid-19 is restricted to the Cancellation / Curtailment section only, which means that emergency medical expenses and other losses will not be declined under the Covid-19 exclusion.', id:'Covid-19', text:'Covid-19'},
        {value:`Business Travel Clause - In addition to general Terms & conditions, this insurance policy covers a Business trip and the below coverages are valid during business activity as well, as per attached Schedule of benefits, and always subject and prior to Underwriters approval. 

        Cover Description                 Sums Insured / Level of Benefit               Excess Each & Every Claim

        Employee Replacement                      EUR 5,000.00                                                   Nil 

        Business Equipment                           EUR 5,000.00                                          EUR 100 excess

        Money                                                 EUR 3,000.00                                           EUR 100 excess

        Business Trip Supplement Cover

        Business Equipment

        WHAT IS COVERED

        1.    Business Equipment

        We will reimburse You up to the sum insured shown in the above Table in respect of the cost of the repair or replacement for Business Equipment in Your care, custody or control which is lost, damaged, stolen or destroyed.

        2.    Business Documents and Records

        We will indemnify You up to the sum insured shown in the Schedule of Benefits Table in respect of the cost of replacing or restoring business documents and records which are Your property or responsibility, following loss or damage during the Trip.

        DEFINITIONS

        Business Equipment

        Any business equipment, trade samples, or articles which belong to You and are in Your custody and are taken on or acquired during a business trip undertaken by You.

        WHAT WE DO NOT COVER

        1.       the Excess amount shown in the Schedule of Benefits Table.

        2.    damage due to wear and tear or gradual deterioration.

        3.    loss of or damage to household effects.

        4.    electrical or mechanical breakdown or derangement.

        5.    loss or damage to Valuables contained in baggage whilst such baggage is in the custody of Carriers and outside Your control.

        6.    theft or attempt of theft of Valuables when they are unattended other than when securely locked in a building or securely locked out of sight inside a motor vehicle.

        7.    loss of cash, currency, bank notes, travellers' cheques, passports, driving licenses, green card, petrol coupons, tickets, ski passes, securities and documents.

        8.    confiscation or detention by Customs or other Authority.

        Business Money

        WHAT IS COVERED

        We will reimburse You, up to an amount not exceeding the sum insured shown in the Schedule of Benefits Table, if during the Trip, Business Money is lost, stolen or destroyed.

        DEFINITIONS

        Business Money

        Coins, bank and currency notes, postal orders, signed travellers' and other cheques, letters of credit, travel tickets, current postage stamps, credit cards and petrol and other coupons, driving licence, and green card.

        WHAT WE DO NOT COVER

        1.       the Excess amount shown in the Schedule of Benefits Table.

        2.    any loss not reported to the Police within 48 hours of discovery.

        3.    Business Money lost in exchange, or through errors or omissions in transactions or purchases.

        4.    loss of Business Money contained in baggage whilst such baggage is in the custody of Carriers and outside Your control.

        5.    confiscation or detention by Customs or other authority.

        6.    any loss in respect of the fraudulent use of credit card, charge card or bankers' card comprising Business Money if You have not complied with the terms and conditions under which the card was issued, including those relating to the safe-keeping and use of the card and the reporting to the Issuing Company or Bank of any misplacement or loss.`, id:'Business_Travel_Clause', text:'Business Travel Clause'},
        {value:`People Already Travelling - Τhere is an  inclusion of a 72 hour waiting period and deletion of the cooling off period in terms of a refund of any premium in the event that the policy is cancelled`, id:'People_Already_Travelling', text:'People Already Travelling'},
        {value:`Excess Waiver Extension Endorsement - Excess Waiver Option is Valid`, id:'Excess_Waiver_Extension_Endorsement', text:'Excess Waiver Extension Endorsement'}
      ],
      resCheckName:null,
      destCheckName:null,
      agesArr:[],
      travellers:[],
      initialRequest:{},
      travellerAges:'',
      updateTravellersFlag:false,
      totalDays:null,
      companyNameDisabled:true,
      product_price:[],
      product_currency:[],
      product_currency_symbol:[],
      product_category:[],
      product_code:[],
      product_description:[],
      product_name:[],
      product_short_description:[],
      primary:null,
      secondary:null,
      previousQuotations:[],
      storageQuote:null,
      sliderStatus:false,
    }
  },
  computed: {
    productsMsg() {
      return this.$t(`message.select_tr_ins`);
    },
    getQuoteMsg() {
      return this.$t(`message.get_quote_err`);
    },
    one_day() {
      return this.$t("message.one_day");
    },
    more_days() {
      return this.$t("message.more_days");
    },
    please_wait() {
      return this.$t("message.please_wait");
    },
    buy_policy_text() {
      return this.$t("message.buy_policy");
    },
    get_quote_text() {
      return this.$t("message.get_quote");
    },
    date_range() {
      return this.$t("message.date_range");
    },
    date_of_birth() {
      return this.$t("message.date_of_birth");
    },
    date_between() {
      return this.$t("message.date_between");
    }
  },
  methods: {
    async saveOffer(data) {
      const url= {
          local: 'http://localhost:8080/v2/app/insert_offer',
          prod: 'https://api-quote.safetravel.today/v2/app/insert_offer'
      };
      try {
        await fetch(url.prod, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
      })
      .then((response)=>response.json())
      .then((res)=>{
        this.offermessagemodalshow=true;
      })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error, couldnt save offer', error);
      }
    },
    handleOfferSubmit() {
      const valid = this.$refs.offerForm.checkValidity()
      if (localStorage.getItem("checkedProducts") === null) {
        this.errorSOffer="No product(s) has beed added to offer"
        return
      }
      if (!valid) {
        this.errorSOffer="Offer name must be at least 6 characters long"
      } else {
        this.sv_offer["token"] = this.agentParam
        this.sv_offer["app"] = "true"
        this.sv_offer["name"] = this.offerName
        if (localStorage.getItem("checkedProducts") !== null) {
          this.sv_offer['offered_products'] = localStorage.getItem("checkedProducts")
        }
        this.sv_offer["trip_information"] = JSON.stringify(this.initialRequest)
        // save offer
        this.saveOffer(this.sv_offer);
      }
      return valid;
    },
    handleOk(bvModalEvent) {
      if (!this.handleOfferSubmit()) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
      }
    },
    formatDate(d) {
      if (d.split("-")[0].length === 4) {
        d = d.split("-").reverse().join('/')
      } 
      return d;
    },
    enableSliderScroll() {
      this.sliderStatus = true;
    },
    disableSliderScroll() {
      this.sliderStatus = false;
    },
    leapYear(year) {
      return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    },
    newQuotation() {
      window.location.reload();
    },
    sessionQuotation(name) {
      this.storageQuote=null;
      if (sessionStorage.getItem(name) !== null) {
        const s = sessionStorage.getItem(name);
        this.storageQuote = JSON.parse(s);
        // console.warn(this.storageQuote);
        // token
        this.initialRequest['token']=this.agentParam;
        // env
        this.initialRequest['env'] = 'production';
        // From Country
        this.initialRequest['res_name'] = this.storageQuote?.res_name;
        this.fromCountryfHidden = this.storageQuote?.res_name;
        this.selectedContryFrom = this.storageQuote?.res_name;
        this.initialRequest['residence'] = this.storageQuote?.residence;
        // To Country
        this.initialRequest['des_name'] = this.storageQuote?.des_name;
        this.toCountryfHidden = this.storageQuote?.des_name;
        this.selectedContryTo = this.storageQuote?.des_name;
        this.initialRequest['destination'] = this.storageQuote?.destination;
        // enable via, set via
        this.viastate = false;
        this.initialRequest['via_countries'] = this.storageQuote?.via_countries;
        this.initialRequest['via'] = this.storageQuote?.via;
        this.selectedVia = this.storageQuote?.via_countries;
        // Input ages
        this.initialRequest['ages'] = this.storageQuote?.ages;
        this.input_ages = this.storageQuote?.ages;
        // Number of travellers
        this.initialRequest['travellersNumber'] = this.storageQuote?.travellersNumber;
        this.travellers_number = this.storageQuote?.travellersNumber;
        // travellers details
        this.numberoftravellers=[];
        for (let i = 0; i < this.storageQuote?.travellers?.length; i++) {
          // this.dateBirthLabel=[];
          this.numberoftravellers.push({
            id:`${i}`, 
            title:this.storageQuote?.travellers[i]?.title?.replace(/\./g, ''), 
            firstname: this.storageQuote?.travellers[i]?.firstname, 
            lastname:this.storageQuote?.travellers[i]?.lastname, 
            date: moment(this.storageQuote?.travellers[i]?.dob, "DD/MM/YYYY").toDate(), 
            mobile: this.storageQuote?.travellers[i]?.phone
          })
          this.dateBirthLabel.push({value: this.storageQuote?.travellers[i]?.age, age:new Date(), en:'', gr:'', fr:'', de:'', es:'', it:'', pl:'', ro:''});
          // travellers details
          const traveller={};
          const age = this.storageQuote?.travellers[i]?.age;
          this.agesArr.push(age);
          traveller["title"] = this.storageQuote?.travellers[i]?.title.replace(/\./g, '') ?? "Mr";
          traveller["firstname"] = this.storageQuote?.travellers[i]?.firstname ?? "first_test_"+age;
          traveller["lastname"] = this.storageQuote?.travellers[i]?.lastname ?? "last_test_"+age;
          traveller["dob"] = this.storageQuote?.travellers[i]?.dob ?? "dob_"+age;
          traveller["age"] = Number(age);
          this.travellers.push(traveller);
          this.initialRequest['travellers']=this.travellers;
        }
        // start date, end date
        const start = this.storageQuote?.start_date ?? new Date();
        const momentStart = moment(start, "DD/MM/YYYY").toDate();
        const end = this.storageQuote?.end_date ?? new Date();
        this.range.start = moment(start, "DD/MM/YYYY").toDate();
        this.range.end = moment(end, "DD/MM/YYYY").toDate();
        this.datePickerKey++;
        // set selected product, tab view
        this.getQuote().then(()=> {
          document.getElementById(`button-tab${this.storageQuote.product}`).click();
          if (this.initialRequest['product_category'] == 'Longstay') {
            document.querySelector(".longstaytripbtn").click();
          } else if (this.initialRequest['product_category'] == 'Annual') {
            document.querySelector(".annualtripbtn").click();
          }
        })
        // family details
        this.initialRequest['email'] = this.storageQuote?.email ?? '';
        this.initialRequest['school'] = this.storageQuote?.school ?? '';
        this.initialRequest["address"] = this.storageQuote?.city + ", " + this.storageQuote?.area + ", " + this.storageQuote?.street + " " + this.storageQuote?.number ?? '';
        this.initialRequest['street'] = this.storageQuote?.street ?? '';
        this.initialRequest['number'] = this.storageQuote?.number ?? '';
        this.initialRequest['area'] = this.storageQuote?.area ?? '';
        this.initialRequest['city'] = this.storageQuote?.city ?? '';
        this.initialRequest['country'] = this.storageQuote?.country ?? '';
        this.initialRequest["telephone"] 	                        = this.storageQuote?.telephone ?? '';
        this.initialRequest["tax_identification_number"] 	        = this.storageQuote?.tax_identification_number ?? '';
        this.initialRequest["postcode"] = this.storageQuote?.additional_info?.postcode ?? '',

        // additional info, contact details to initialRequest
        this.initialRequest['additional_info']={
          'contracted_party_type' : this.storageQuote?.additional_info?.contracted_party_type ?? 'no',
          'company_name' : this.storageQuote?.additional_info?.company_name ?? '',
          'document_type' : this.storageQuote?.additional_info?.document_type ?? 'afm',
          'document_number' : this.storageQuote?.additional_info?.document_number ?? '',
          'document_authority' : this.storageQuote?.additional_info?.document_authority ?? 'afm',
          'document_authority_certification' : this.storageQuote?.additional_info?.document_authority_certification ?? '',
          'trip_information_text' : this.storageQuote?.additional_info?.trip_information_text ?? '',
          'arrival_city' : this.storageQuote?.additional_info?.city ?? '',
          'travel_price' : this.storageQuote?.additional_info?.travel_price ?? '',
          'postcode' : this.storageQuote?.additional_info?.postcode ?? '',
          'phone' : this.storageQuote?.telephone ?? '',
          'mobile' : this.storageQuote?.telephone ?? '',
          'country' : this.storageQuote?.additional_info?.country ?? '',
          'street' : this.storageQuote?.additional_info?.street ?? '',
          'number' : this.storageQuote?.additional_info?.number ?? '',
          'area' : this.storageQuote?.additional_info?.area ?? '',
          'city' : this.storageQuote?.additional_info?.city ?? '',
        }
        // family form update ui
        this.contact_email = this.initialRequest['email'];
        this.customer_street = this.initialRequest['street'];
        this.street_number = this.initialRequest['number'];
        this.customer_area = this.initialRequest['area'];
        this.customer_city = this.initialRequest['city'];
        this.tax_identification_number = this.initialRequest["tax_identification_number"];
        this.customer_telephone = this.initialRequest["telephone"];
        this.customer_zip_code = this.initialRequest.postcode;
        this.selectedContryPolicyHolder = this.initialRequest['country'];
        // additional info update ui
        this.company_name = this.initialRequest['additional_info'].company_name;
        this.document_number = this.initialRequest['additional_info'].document_number;
        this.document_authority_certification = this.initialRequest['additional_info'].document_authority_certification;
        this.trip_info_text = this.initialRequest['additional_info'].trip_information_text;
        this.arrival_city = this.initialRequest['additional_info'].arrival_city;
        this.travel_price = this.initialRequest['additional_info'].travel_price;
        // addition info update ui select 
        this?.documentAuthority?.forEach((e)=> {
          if(e.value == this.initialRequest['additional_info'].document_authority) {
            this.documentAuthoritySelected = e.text;
          }
        })
        this?.contractedPartyType?.forEach((e)=> {
          if(e.value == this.initialRequest['additional_info'].contracted_party_type) {
            this.contractedPartyTypeSelected = e.text;
          }
        })
        this?.documentType?.forEach((e)=> {
          if(e.value == this.initialRequest['additional_info'].document_type) {
            this.documentTypeSelected = e.text;
          }
        })

      } else {
        window.location.reload();
      }
    },
    checkPreviousQuotations() {
      const arr=[];
      Object.keys(sessionStorage).map(function(c){
        const getS = sessionStorage.getItem(c);
        const getParsed = JSON.parse(getS);
        const name = getParsed?.travellers[0]?.lastname ?? '';
        const product = getParsed?.product_name ?? '';
        const namepr = `${name} - ${product}`;
        arr.push([c,namepr]);
      });
      this.previousQuotations = arr;
    },
    populateFormDts() {
      for(let i=0; i<100; i++) {
        this.loadFormDts[0][`traveller_title${i+1}`] = 'Mr';
        this.loadFormDts[1][`firstName${i+1}`] = '';
        this.loadFormDts[2][`lastName${i+1}`] = '';
        this.loadFormDts[3][`mobile${i+1}`] = '';
      }
    },
    copylink() {
      const dummy = document.createElement('input');
      navigator.clipboard.writeText(this.hrefpaymentlink);
    },
    async saved_transactions() {
      const url = 'https://api-quote.safetravel.today/v2/app/saved_transactions';
      const data = {
        product: this.initialRequest['product'] + ":" + this.initialRequest['price']+'€',
        trip_information: JSON.stringify(this.initialRequest),
        token: this.initialRequest['token'],
        app:'app'
      };
      try {
        await fetch(url, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=>{
          if (res) {
            this.errorbuyProductModalMsg="Transaction have been saved."
          } else {
            this.errorbuyProductModalMsg="There's been an error on our end, kindly retry later."
          }
        })
        } catch(err) {
          console.warn(err)
          this.errorbuyProductModalMsg="There's been an error on our end, kindly retry later."
        }
    },
    async buyProduct(e){
      this.buypolicystatus=false;
      e.target.disabled = true;
      e.target.innerHTML = this.please_wait;
      const prod = 'https://api-quote.safetravel.today/v2/app/reserve';
      // if annual, update end_date
      if (this.initialRequest.product_category == 'Annual') {
        this.initialRequest['end_date'] =  this.initialRequest['annual_date'];
      }
      // buy
      const response=await fetch(prod, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.initialRequest)
      })
      if(response.status==200) {
        return response.json().then((result_1) => {
          // console.warn(result_1)
          if(result_1.error||!result_1?.token) {
            if(response.status>400 && response.status<=599) {
              this.errorbuyProductModalMsg="There's been an error on our end, kindly retry later."
            } else {
              this.errorbuyProductModalMsg=result_1.error
            }
            this.paymentmodalerrorshow=true
            return
          }
          if(result_1.payable_here=='-') {
            this.display_payment_ammount=false
          } else {
            this.display_payment_ammount=true
          }
          const token=result_1.token??''
          this.displayproduct=this.initialRequest?.product_name??''
          this.displayprice=result_1?.gross_price+"€"??''
          this.payablefrom=result_1?.payable_from??''
          this.payableto=result_1?.payable_to??''
          this.payableamount=result_1?.payable_amount+"€"??''
          this.hrefpaymentlink=result_1?.payable_here??''
          this.paymentdts=true
          this.paymentmodalshow=true
          this.buypolicystatus=true
        }).then((data_1) => {
          this.switched_checked_accept=false
          e.target.innerHTML=this.buy_policy_text
          e.target.disabled = false
        })
      } else {
        this.buypolicystatus=false
        this.paymentmodalerrorshow=true
        e.target.innerHTML=this.buy_policy_text
        e.target.disabled = false
        this.saved_transactions()
        return
      }
	  },
    checkIfProducts(cat) {
      if(!document.querySelectorAll(cat).length) {
        this.noProducrsAlert();
      }
    },
    showSelectedTypeProducts(elem,removetypeone,removetypetwo) {
      const rem_classes = [removetypeone, removetypetwo];
      const single = document.querySelector('.singletripbtn');
      const longstay = document.querySelector('.longstaytripbtn');
      const annual = document.querySelector('.annualtripbtn');

      if (elem) {
        if (elem.target.classList.contains("singletripbtn")) {
          single.classList.add('active');
          longstay.classList.remove('active');
          annual.classList.remove('active');
          this.checkIfProducts(".SingleTrip");
        }else if (elem.target.classList.contains("longstaytripbtn")) {
          longstay.classList.add('active');
          single.classList.remove('active');
          annual.classList.remove('active');
          this.checkIfProducts(".Longstay");
        }
        if (elem.target.classList.contains("annualtripbtn")) {
          annual.classList.add('active');
          single.classList.remove('active');
          longstay.classList.remove('active');
          this.checkIfProducts(".Annual");
        }
      } else {
        single.classList.add('active');
        longstay.classList.remove('active');
        annual.classList.remove('active');
        this.checkIfProducts(".SingleTrip");
      }
      document.querySelectorAll(".product_col").forEach((e,i)=> {
        if (rem_classes.some(classname=>e.classList.contains(classname))) {
          e.classList.add("d-none");
        } else {
          e.classList.remove("d-none");
        }
      })

    },
    setContractedPartyType(e) {
      if (e.value == 'yes') {
        this.setcompanyName = true;
      } else {
        this.setcompanyName = false;
      }
      this.companyNameDisabled = !this.companyNameDisabled;
    },
    medicalOptions(elem) {
      if (elem != 'A') {
        this.switched_checked_accept=false;
      }
      this.checkOptions();
    },
    treatmentOptions(elem) {
      if (elem != 'A') {
        this.switched_checked_accept=false;
      }
      this.checkOptions();
    },
    coveragesOptions(elem) {
      if (elem != 'A') {
        this.switched_checked_accept=false;
      }
      this.checkOptions();
    },
    checkOptions() {
      if (this.selected_coverages == 'A' && this.selected_treatment == 'A' && this.selected_medical == 'A') {
        this.buypolicystatus=false;
      } else {
        this.buypolicystatus=true;
      }
    },
    check_allow_buy(elem) {
      if (elem == true && this.selected_coverages == 'A' && this.selected_treatment == 'A' && this.selected_medical == 'A') {
        // allow buy policy
        this.buypolicystatus=false;
      } else {
        this.buypolicystatus=true;
      }
    },
    compinedHTML(elem,item) {
      return elem + " " + (item+1);
    },
    scrollIntoview(elem) {
      const e = document.querySelector(elem);
      e.scrollIntoView({ behavior: 'smooth', block: "end"});
    },
    selectProduct(productCode) {
      document.querySelectorAll('.buttonPrice').forEach((e)=> {
        e.value='Select';
      })
      document.getElementById(productCode.id).value="Selected";
      const product = productCode.getAttribute('data-product');
      const name = productCode.getAttribute('data-productname');
      const price = productCode.getAttribute('data-productprice');
      const category = productCode.getAttribute('data-productcategory');
      // update initialRequest
      this.initialRequest['product'] = product;
      this.check_product = this.initialRequest['product'];
      this.initialRequest['product_name'] = name;
      this.initialRequest['price'] = price;
      this.initialRequest['product_category'] = category;
      this.initialRequest['env'] = 'production';
      this.productIsSelected=true;
      if (product.includes(['product-single-6']) || product.includes(['product-longstay-6']) || product.includes(['product-annual-6'])) {
        this.product_is_mondial=true;
        this.product_is_kemter=false;
        this.product_is_battleface=false;
        this.product_is_sompo=false;
        this.additional_info_visible=false;
      } else if (product.includes(['product-single-8']) || product.includes(['product-longstay-8']) || product.includes(['product-annual-8'])) {
        this.product_is_kemter=true;
        this.product_is_mondial=false;
        this.product_is_battleface=false;
        this.product_is_sompo=false;
        this.additional_info_visible=false;
      } else if (product.includes(['product-single-4']) || product.includes(['product-longstay-4']) || product.includes(['product-annual-4'])) {
        this.product_is_battleface=true;
        this.product_is_sompo=false;
        this.product_is_kemter=false;
        this.product_is_mondial=false;
        this.additional_info_visible=false;
      } 
      else if (product.includes(['product-single-9']) || product.includes(['product-longstay-9']) || product.includes(['product-annual-9'])) {
        this.product_is_mondial=false;
        this.product_is_kemter=false;
        this.product_is_battleface=false;
        this.product_is_sompo=false;
        this.additional_info_visible=false;
      }
      else if (product.includes(['product-single-3']) || product.includes(['product-longstay-3']) || product.includes(['product-annual-3'])) {
        this.product_is_mondial=false;
        this.product_is_kemter=false;
        this.product_is_battleface=false;
        this.product_is_sompo=true;
        this.additional_info_visible=false;
      }
    },
    classicViewLayout() {
      const panel = document.querySelector("#products_panel_list");
      this.showProductsClassic = true;
      this.showProductsList = false;
      if (panel) {
        panel.ontransitionrun = () => {
          this.showSelectedTypeProducts(null,'Longstay','Annual');
          this.selectedProducts();
        };
      }
    },
    ListViewLayout() {
      const panel = document.querySelector("#products_panel");
      this.showProductsClassic = false;
      this.showProductsList = true;
      if (panel) {
        panel.ontransitionrun = () => {
          this.showSelectedTypeProducts(null,'Longstay','Annual');
          this.selectedProducts();
        };
      }
    },
    selectedProducts() {
      if (localStorage.getItem('checkedProducts') !== null && localStorage.getItem('checkedProducts') !== "") {
        const x = JSON.parse(localStorage.getItem('checkedProducts'));
        for (const [key, value] of Object.entries(x)) {
          document.querySelector("#offerpr_"+key).checked=true
          document.querySelector("#offercp_"+key).checked=true
        }
      }
    },
    annualDate() {
      let annual_date = new Date(this.range.start);
      annual_date.setDate(annual_date.getDate() + 364);
      const annual_date_locale = annual_date.toLocaleDateString('en-GB',{year: 'numeric', month: '2-digit', day: '2-digit'});
      this.initialRequest['annual_date'] = annual_date_locale;
    },
    daterangePickerToday() {
      //today dates to dd/mm//year format
      const start_date = this.range.start.toLocaleDateString('en-GB',{year: 'numeric', month: '2-digit', day: '2-digit'});
      const end_date = this.range.end.toLocaleDateString('en-GB',{year: 'numeric', month: '2-digit', day: '2-digit'});
      this.totalDays='1 day';
      //today dates on initialRequest in case of no select
      this.initialRequest['start_date'] = start_date;
      this.initialRequest['end_date'] = end_date;
      this.initialRequest['days'] = 1;
      // annual end date
      this.annualDate();
    },
    daterangePicker(e) {
      const dt = document.querySelector("label[for='daterange']"); 
      //selected dates to dd/mm//year format
      const start_date = e.start.toLocaleDateString('en-GB',{year: 'numeric', month: '2-digit', day: '2-digit'});
      const end_date = e.end.toLocaleDateString('en-GB',{year: 'numeric', month: '2-digit', day: '2-digit'});
      // To calculate the time difference of two dates
      const Difference_In_Time = Math.abs(e.end.getTime() - e.start.getTime());
      const Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24))+1;
      //update initialRequest
      this.initialRequest['start_date'] = start_date;
      this.initialRequest['end_date'] = end_date;
      this.initialRequest['days'] = Difference_In_Days;
      if (Difference_In_Days > 1) {
        dt.innerHTML=`${this.date_range} - ${Difference_In_Days} ${this.more_days}`;
      } else {
        dt.innerHTML=`${this.date_range} - 1 ${this.one_day}`;
      }
      // annual end date
      this.annualDate();
      // empty get quote 
      this.clearQuote();
    },
    showClauses(elem) {
      const textarea = document.querySelector("#showclaussess");
      const textarea_val = [];
      elem.forEach((e,i)=> {
        if (e.id == 'Covid-19') {
          textarea_val.push(e.value + '\n\n');
        } 
        if (e.id == 'Business_Travel_Clause') {
          textarea_val.push(e.value + '\n\n');
        } 
        if (e.id == 'People_Already_Travelling') {
          textarea_val.push(e.value + '\n\n');
        } 
        if (e.id == 'Excess_Waiver_Extension_Endorsement') {
          textarea_val.push(e.value + '\n\n');
        } 
      })
      textarea.value=textarea_val.join('');
    },
    isNumber(event) {
      event = (event) ? event : window.event;
      const charCode = (event.which) ? event.which : event.keyCode;
      if (event.keyCode === 8 || event.keyCode === 46) {
          return true;
      } else if ( (charCode < 48 || charCode > 57) && charCode != 44 ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    checkAdult(age) {
      return age < 18;
    },
    updateAges(){
      this.numberoftravellers=[];
      this.dateBirthLabel=[];
      this.agesArr=[];
      this.travellers=[];
      const agesinput = document.querySelector("#input_ages").value;
      const agesNumArr = agesinput.split(",").map(Number);
      // duplicate commas...
      if(agesinput.match(/,{2,}/g)) {
        this.duplicatecommas='No duplicate commas allowed.'
        return false;
      } else {
        this.duplicatecommas=null;
      }
      // check if adult
      if (agesNumArr.every(this.checkAdult)) {
        this.adult_supervisor='There needs to be an adult supervisor included as a co-traveller.'
        return false;
      } else {
        this.adult_supervisor=null;
      }

      const ages = document.querySelector("#input_ages").value.split(',');
      const travellers_number = document.querySelector("#travellers_number");
      travellers_number.value=ages.length;
      this.agesArr.push(ages);
      this.initialRequest['travellersNumber'] = ages.length;
      this.updateTravellersFlag=true;
      // create values based on travellersNumber, update inputs, calc birthday input
      for (let i = 0; i < ages.length; i++) {
        const dt = new Date();
        const calculateYear = dt.getFullYear() - ages[i];
        let calc_date='';
        if (new Date().getDate() == 1) {
          calc_date = (new Date().getMonth()+1) +"-"+ (new Date().getDate()) +"-"+ calculateYear;
        } else {
          calc_date = (new Date().getMonth()+1) +"-"+ (new Date().getDate()-1) +"-"+ calculateYear;
        }

        this.numberoftravellers.push({id:`${i}`, title:this.loadFormDts[0][`traveller_title${i+1}`], firstname: this.loadFormDts[1][`firstName${i+1}`], lastname:this.loadFormDts[2][`lastName${i+1}`], date: calc_date, mobile: this.loadFormDts[3][`mobile${i+1}`]})
        this.dateBirthLabel.push({value: this.date_of_birth, age:new Date(), en:'', gr:'', fr:'', de:'', es:'', it:'', pl:'', ro:''});
      }
    },
    addTravellers() {
      // check if user didnt remove duplicate commas...
      const agesinput = document.querySelector("#input_ages").value;
      if(agesinput.match(/,{2,}/g)) {
        this.input_ages='';
        this.travellers_number=0;
        document.querySelector("#travellers_number").value=0;
        this.duplicatecommas=null;
        this.adult_supervisor=null;
        return false;
      }
      // check if all users are above 18yrs old
      const agesNumArr = agesinput.split(",").map(Number);
      if (agesNumArr.every(this.checkAdult)) {
        this.input_ages='';
        this.travellers_number=0;
        document.querySelector("#travellers_number").value=0;
        this.duplicatecommas=null;
        this.adult_supervisor=null;
        return false;
      } 
      if (this.updateTravellersFlag) {
        this.agesArr = this.agesArr[0].map(i=>Number(i));
        // maximum allowed travellers
        if (this.agesArr.length > 100) {
          this.maxTravellersAlert();
        }
        for (let i = 0; i < this.agesArr.length; i++) {
          let traveller={};
          let age = this.agesArr[i];
          traveller["title"] = "Mr";
          traveller["firstname"] = "first_test_"+age;
          traveller["lastname"] = "last_test_"+age;
          traveller["dob"] = "dob_"+age;
          traveller["age"] = Number(age);
          this.travellers.push(traveller);
          this.initialRequest['travellers']=this.travellers;
        }
      }
      this.updateTravellersFlag=false;
      this.initialRequest['ages'] = agesinput;
      // empty get quote 
      this.clearQuote();
    },
    formSubmitted() {
      location.reload();
    },
    synchBehalfOf(e) {
      this.selectedBehalfOfHidden = e.text
    },
    agentSelected(e) {
      // empty get quote 
      this.clearQuote();
    },
    select_country_from(e) {
      this.initialRequest['res_name']=e.text;
      this.initialRequest['residence']=Number(e.value);
      this.fromCountryfHidden = e.text;
      // enable via
      if (this.initialRequest['residence'] && this.initialRequest['destination']) {
        this.viastate = false;
      }
      // empty get quote 
      this.clearQuote();
    },
    select_title_to(elem, num) {
      const hidden = document.querySelector(`#traveller_title_hidden${num+1}`);
      hidden.value = elem.text + ".";
      this.loadFormDts[0][`traveller_title${num+1}`] = elem;
    },
    select_country_to(e) {
      this.initialRequest['des_name']=e.text;
      this.initialRequest['destination']=Number(e.value);
      this.toCountryfHidden = e.text
      // enable via
      if (this.initialRequest['residence'] && this.initialRequest['destination']) {
        this.viastate = false;
      }
      // empty get quote 
      this.clearQuote();
    },
    select_country_via(e) {
      const res = this.initialRequest['residence'];
      const dest = this.initialRequest['destination'];
      const viaCodes=[];
      const viaCountries=[];
      e.forEach((elem,i)=> {
        viaCodes.push(elem.value);
        viaCountries.push(elem.text);
      })
      if (res && dest) {
        const vv=viaCodes.join('-');
        this.initialRequest['via'] = vv ? `${res}-${vv}-${dest}` : [ res, dest ].join('-');
        this.initialRequest['via_countries']=viaCountries;
      } 
      // empty get quote 
      this.clearQuote();
    },
    validationForm() {
      this.initialRequest['token']=this.agentParam;
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    countDownChanged1(dismissCountDown1) {
      this.dismissCountDown1 = dismissCountDown1
    },
    countDownChanged2(dismissCountDown2) {
      this.dismissCountDown2 = dismissCountDown2
    },
    showAlert(msg) {
      this.dismissCountDown = this.dismissSecs
      this.alertMsg=msg
    },
    maxTravellersAlert() {
      this.dismissCountDown2 = this.dismissSecs2
    },
    noProducrsAlert() {
      this.dismissCountDown1 = this.dismissSecs1
    },
    validationFormInfo() {
      this.inputFillAge();
      if (this.productIsSelected == true) {
        return true;
      } else {
        this.scrollIntoview("#getQuote");
        this.showAlert(this.productsMsg);
        return false;
      }
    },
    dynamicInputValidate() {
      //focus,blur event for vee-validate dynamic inputs
      document.querySelectorAll(".travellersValidate").forEach((e)=> {
        e.focus();
        e.blur();
      })
    },
    inputFillAge() {
      const thirdonemonths = [1,3,5,7,8,10,12];
      const dt = new Date();
      const newgetmonth = (dt.getMonth());

      for (let i = 0; i < this.initialRequest['travellersNumber']; i++) {
        let calculateYear = dt.getFullYear() - this.agesArr[i];
        if (dt.getDate() == 1) {
          // months with 31 days
          if (thirdonemonths.includes(newgetmonth)) {
            this.todayval = 31 + "-" + ('0' + newgetmonth).slice(-2) + "-" + calculateYear;
          } 
          // calc febryary days
          else if(newgetmonth == 2) {
            if (this.leapYear(dt.getFullYear())) {
              this.todayval = 29 + "-" + ('0' + newgetmonth).slice(-2) + "-" + calculateYear;
            } else {
              this.todayval = 28 + "-" + ('0' + newgetmonth).slice(-2) + "-" + calculateYear;
            }
          // months with 30 days
          } else {
            this.todayval = 30 + "-" + ('0' + newgetmonth).slice(-2) + "-" + calculateYear;
          }
        } 
        else {
          this.todayval = ('0' + (dt.getDate()-1)).slice(-2) + "-" + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + calculateYear;
        }
        this.prevval = ('0' + dt.getDate()).slice(-2) + "-" + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + (calculateYear-1);

        this.dateBirthLabel[i].value = this.date_of_birth+' ('+this.agesArr[i]+' ' + this.date_between+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].en = 'Date of Birth ('+this.agesArr[i]+' years old, between: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].fr = 'Date de naissance ('+this.agesArr[i]+' ans, entre: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].de = 'Geburtsdatum ('+this.agesArr[i]+' Jahre alt, zwischen: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].gr = 'Ημερομηνία γέννησης ('+this.agesArr[i]+' χρονών, μεταξύ: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].es = 'Fecha de nacimiento ('+this.agesArr[i]+' años, entre: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].pl = 'Data urodzenia ('+this.agesArr[i]+' lat, między: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].ro = 'Data nașterii ('+this.agesArr[i]+' ani, cu vârsta cuprinsă între: '+this.prevval+' - '+this.todayval+')';
        this.dateBirthLabel[i].it = 'Data di nascita ('+this.agesArr[i]+' anni, tra: '+this.prevval+' - '+this.todayval+')';
      }
    },
    moment: function () {
      return moment();
    },
    getAge(birthDate) {
      return moment().subtract(1, "days").diff(moment(birthDate, "DD-MM-YYYY"), 'years');
    },
    calulateAge() {
      this.birthDatesArr=[];
      document.querySelectorAll(".form_control_dateofbirth").forEach((e,i)=> {
        const picker_year = e.value.slice(6);
        const picker_day = e.value.slice(0,2);
        const picker_month = e.value.slice(3,5);
        const formatted_date =  picker_day + "-" + picker_month + "-" + picker_year;
        this.birthDatesArr.push(this.getAge(formatted_date));
        // console.warn(this.getAge(formatted_date))
      })
    },
    checkAge(a,b) {
      // check which datepicker index doesnt match
      const result = a.map(function(item,i) {
        if (item !== b[i]) {
          document.getElementById("dateofbirth"+(i+1)).style.border = "1px solid #ea5455";
        } else {
          document.getElementById("dateofbirth"+(i+1)).style.border = "1px solid #ced4da";
        }
      })
      // return equal arrays
			return JSON.stringify(a) === JSON.stringify(b);
    },
    validationFormAddress() {
      this.dynamicInputValidate();
      this.calulateAge();
      if (!this.checkAge(this.birthDatesArr, this.agesArr)) {
        // console.warn(this.birthDatesArr);
        // console.warn(this.agesArr);
        return false;
      }
      // start additional info, contact details to initialRequest
      this.initialRequest['additional_info']={
        'contracted_party_type' : this.contractedPartyTypeSelected.value ?? '',
        'company_name' : this.company_name,
        'document_type' : this.documentTypeSelected.value ?? '',
        'document_number' : this.document_number,
        'document_authority' : this.documentAuthoritySelected.value ?? '',
        'document_authority_certification' : this.document_authority_certification,
        'trip_information_text' : this.trip_info_text,
        'arrival_city' : this.arrival_city,
        'travel_price' : this.travel_price,
        'postcode' : this.customer_zip_code,
        'phone' : this.customer_telephone,
        'mobile' : this.customer_telephone,
        'country' : this.selectedContryPolicyHolder?.text ?? '',
        'street' : this.customer_street,
        'number' : this.street_number,
        'area' : this.customer_area,
        'city' : this.customer_city,
      }
      this.initialRequest['email'] = this.contact_email;
      this.initialRequest["address"] = this.customer_city + ", " + this.customer_area + ", " + this.customer_street + " " + this.street_number;
      this.initialRequest['street'] = this.customer_street;
      this.initialRequest['number'] = this.street_number;
      this.initialRequest['area'] = this.customer_area;
      this.initialRequest['city'] = this.customer_city;
      this.initialRequest['country'] = this.selectedContryPolicyHolder?.text ?? '';

      this.initialRequest["telephone"] 	                        = this.customer_telephone;
      this.initialRequest["tax_identification_number"] 	        = this.tax_identification_number;
      this.initialRequest["agencyid"]        = "" ;
      this.initialRequest["postcode"] = this.customer_zip_code;
      this.initialRequest["school"] = this.customer_school ?? '';

      for (let i = 1; i <= this.initialRequest["travellers"].length; i++){
        const m = document.querySelector(`#mobile${i}`).value;
				this.initialRequest["travellers"][i-1]["title"] = document.querySelector(`#traveller_title_hidden${i}`).value;
				this.initialRequest["travellers"][i-1]["firstname"] = document.querySelector(`#firstName${i}`).value;
				this.initialRequest["travellers"][i-1]["lastname"] = document.querySelector(`#lastName${i}`).value;
				this.initialRequest["travellers"][i-1]["dob"] = document.querySelector(`#dateofbirth${i}`).value.split('-').join('/');
        if (m) {
          this.initialRequest["travellers"][i-1]["phone"] = m;
        } else {
          this.initialRequest["travellers"][i-1]["phone"] = this.customer_telephone;
        }
			}
      // end additional info, contact details to initialRequest
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      sessionStorage.setItem("initialRequest_"+Date.now(), JSON.stringify(this.initialRequest));
      // console.warn(this.initialRequest);
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    waitForElm(selector) {
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
      });
    },
    async getQuote(e) {
      if (this.agesArr.length > 100) {
        this.maxTravellersAlert();
        return false;
      }
      const products_panel = document.getElementById("products_panel");
      const products_panel_list = document.getElementById("products_panel_list");
      this.initialRequest['product']=[];
      this.initialRequest['env']='production';
      this.initialRequest['token']=this.agentParam;
      const getQuoteBtn = document.getElementById("getQuote");
      const url = {local:'products_collinson_formatted.json',remote:{all:'https://api-quote.safetravel.today/v2/app/quote'}, available:'https://api-quote.safetravel.today/v2/products/available'};
      // empty div with products
      if (products_panel) {
        products_panel.innerHTML = "";
      }
      if (products_panel_list) {
        products_panel_list.innerHTML = "";
      }
      // empty products array
      this.ajax_products=[];
      this.all_products_arr=[];
      // unselect product flag
      this.productIsSelected=false;

      try {
        await fetch(url.remote.all, {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(this.initialRequest)
        })
        .then(response => response.json())
        .then((data)=> {
          getQuoteBtn.innerHTML = this.please_wait;
          getQuoteBtn.disabled = true;
            for (let i = 0; i < data.length; i++) {
              // add products
              this.all_products_arr.push(data[i]);
            }
        })
        .then((res)=> {
          this.ajax_products=this.all_products_arr;
          //show products
          this.showProducts=true;
          getQuoteBtn.innerHTML = this.get_quote_text;
          getQuoteBtn.disabled = false;
        })
        .then(()=> {
          //show single products as default
          this.showSelectedTypeProducts(null,"Longstay","Annual");
        })
      } catch(err) {
        this.showAlert(this.getQuoteMsg)
      }
    },
    saveFN(event, id) {
        this.loadFormDts[1][`firstName${id+1}`] = event;
    },
    saveLN(event, id) {
        this.loadFormDts[2][`lastName${id+1}`] = event;
    },
    saveMB(event, id) {
        this.loadFormDts[3][`mobile${id+1}`] = event;
    },
    clearQuote() {
      this.showProducts=false;
      this.productIsSelected=false;
    },
  },
  mounted() {
    if (localStorage.getItem("checkedProducts") !== null) {
      localStorage.removeItem("checkedProducts");
    }
    this.populateFormDts();
    this.daterangePickerToday();
    this.checkPreviousQuotations();
  },
  watch: {
    sliderStatus: function() { 
      const slider = document.querySelector('#products_panel_list') ? document.querySelector('#products_panel_list') : null;
      if (!slider) {
        return;
      }
      let mouseDown = false;
      let startX, scrollLeft;

      const startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      const stopDragging = function (event) {
        mouseDown = false;
      };

      slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
      
    },
    '$i18n.locale': function(newVal, oldVal) {
      if (newVal == 'en') {
        this.dateBirth_i = 'en';
      } 
      else if (newVal == 'fr') {
        this.dateBirth_i = 'fr';
      } 
      else if (newVal == 'de') {
        this.dateBirth_i = 'de';
      } 
      else if (newVal == 'gr') {
        this.dateBirth_i = 'gr';
      }
      else if (newVal == 'es') {
        this.dateBirth_i = 'es';
      }
      else if (newVal == 'pl') {
        this.dateBirth_i = 'pl';
      }
      else if (newVal == 'ro') {
        this.dateBirth_i = 'ro';
      }
      else if (newVal == 'it') {
        this.dateBirth_i = 'it';
      }
    },
    ajax_products: {
       handler(val) {
        // console.warn(val)
        this.product_price=[];
        this.product_currency=[];
        this.product_currency_symbol=[];
        this.product_category=[];
        this.product_code=[];
        this.product_description=[];
        this.product_name=[];
        this.product_short_description=[];
        this.primary=[];
        this.secondary=[];
        
        for (let i = 0; i < val.length; i++) {
          this.product_price.push(val[i].gross_price ?? '');
          this.product_currency.push(val[i].currency ?? '');
          this.product_currency_symbol.push(val[i].currency_symbol ?? '');
          this.product_category.push((val[i].product?.product_info?.product_category)?.replace(/ +/g, "") ?? '');
          this.product_code.push(val[i].product?.product_info?.product_code ?? '');
          this.product_description.push(val[i].product?.product_info?.product_description ?? 'desc. available soon.');
          this.product_name.push(val[i].product?.product_info?.product_name ?? 'Unavailable');
          this.product_short_description.push(val[i].product?.product_info?.product_short_description ? val[i].product.product_info.product_short_description : "desc. available soon.");
          this.primary.push(val[i].coverages?.primary);
          this.secondary.push(val[i].coverages?.secondary);   
        }
        this.resCheckName = this.initialRequest['res_name'];
        this.destCheckName = this.initialRequest['des_name'];
      },
      deep: true,
    }
  }
}
</script>
<style scoped>
  .singletripbtn {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .annualtripbtn {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    display:flex;
    align-items:center;
  }
  .mb-cst {
    margin-bottom: 3px;
  }
  .mt-cst {
    margin-bottom: 10px;
  }
  .nowrapview {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  #products_panel_list {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
  }
  #products_panel_list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  #products_panel_list::-webkit-scrollbar {
    width: 11px;
    height: 11px;
    background-color: #F5F5F5;
  }
  #products_panel_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--foreground-color);
  }
  /* start bs alert */
  .alert-danger {
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  .alert-dismissible {
    padding-right: 4rem;
  }
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    cursor: pointer;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  /* end bs alert */
</style>
